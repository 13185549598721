/*
 * @Date: 2023-03-16 13:36:18
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 23:32:36
 * @FilePath: \F1-M2-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 */

import { searchdomain } from "../default/otherDomains";
let mainDomain;
if (process.browser) {
    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
        mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
    }
}

const traceConfigs = {
    'm2-001': {
        piwik : '851db217-163c-4daf-a72d-fbf3ec91267e',
        "51la": searchdomain[mainDomain] ? searchdomain[mainDomain]['51la'] : false,
        ga: searchdomain[mainDomain] ? searchdomain[mainDomain].ga : 'UA-198324647-3',
    },
    'm2-002' : {
        piwik : false,
        "51la" : false,
        ga : false,
    },
    'm2-003' : {
        piwik : '851db217-163c-4daf-a72d-fbf3ec91267e',
        "51la" : false,
        ga : 'UA-198324647-3'
    },
    'm2-004' : {
        piwik : '851db217-163c-4daf-a72d-fbf3ec91267e',
        "51la" : false,
        ga : 'UA-198324647-3'
    },
    'm2-005' : {
        piwik : false,
        "51la" : false,
        ga : false,
    },
}
traceConfigs['m2-006'] = traceConfigs['m2-001'];
traceConfigs['m2-007'] = traceConfigs['m2-001'];

export default traceConfigs;

/*
 * @Date: 2023-04-12 13:50:19
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 23:36:01
 * @FilePath: \F1-M2-QRP-CODE\config\default\banner.js
 */
export const bannerConfigs = {

    'm2-001' : [{
        cmsImageUrlWeb: '/static/images/m2-001/QRP-NUFC-Desktop.jpg',
        cmsImageUrlMobile: '/static/images/m2-001/QRP-NUFC-Mobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm2-002' : [{
        cmsImageUrlWeb : '/static/images/m2-002/banner_f1m2_desktop.jpg',
        cmsImageUrlMobile:'/static/images/m2-002/banner_f1m2_mobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm2-003' : [{
        cmsImageUrlWeb : '/static/images/m2-003/F1M2_003_Desktop.jpg',
        cmsImageUrlMobile:'/static/images/m2-003/F1M2_003_mobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],


    'm2-004' : [{
        cmsImageUrlWeb : '/static/images/m2-004/F1M2_004_Desktop.png',
        cmsImageUrlMobile:'/static/images/m2-004/F1M2_004_mobile.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm2-005' : [{
        cmsImageUrlWeb : '/static/images/m2-005/F1M2_005_Desktop.jpg',
        cmsImageUrlMobile:'/static/images/m2-005/F1M2_005_mobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm2-006' : [{
        cmsImageUrlWeb : '/static/images/m2-006/F1M2_006_Desktop.png',
        cmsImageUrlMobile:'/static/images/m2-006/F1M2_006_mobile_.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm2-007' : [{
        cmsImageUrlWeb : '/static/images/m2-007/F1M2_007_Desktop.png',
        cmsImageUrlMobile:'/static/images/m2-007/F1M2_007_mobile_.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
};

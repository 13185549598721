/*
 * @Date: 2023-04-25 22:57:20
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-15 18:20:13
 * @FilePath: \F1-M2-QRP-CODE\config\trace\affDomains.js
 */
const affDomains = {
    // "localhost": "323667", //測試用
    // "fun88.biz" : "323667", // 测试使用
    "fun88gol.com" : "323667",
};
export default affDomains;

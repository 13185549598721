/*
 * @Date: 2023-04-25 23:04:13
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 23:22:14
 * @FilePath: \F1-M2-QRP-CODE\config\default\otherDomains.js
 */
export const searchdomain =  {
    "localhost" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "fun3688.com" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "funfree12.com" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "fun88event.com" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "fun88epl.com" : {
        piwik: false,
        "51la": false,
        ga: false,
    },
    "fun88fifa.com" : {
        piwik: false,
        "51la": false,
        ga: false,
    }
}